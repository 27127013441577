
















import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import FscSimpleCard from "@/components/Card/FscSimpleCard.vue";
import AbortButton from "@/components/Button/AbortButton.vue";
import SaveButton from "@/components/Button/SaveButton.vue";
import { getFile } from "@/utils/File";

@Component({
  components: { AbortButton, FscSimpleCard, SaveButton },
})
export default class InvoiceDocumentPreview extends Vue {
  public name = "InvoiceDocumentPreview";

  public isLoading = false;

  @Prop()
  public studentEducationId!: any;

  private source = "";

  public mounted(): void {
    if (this.studentEducationId) {
      let resourceId = this.studentEducationId;
      if (Array.isArray(this.studentEducationId) && this.studentEducationId.length > 0) {
        resourceId = this.studentEducationId[0];
      } else if (typeof this.studentEducationId === "string") {
        resourceId = Number(this.studentEducationId);
      }
      this.generateInvoicePreview(resourceId);
    }
  }

  @Watch("studentEducationId")
  public onItemChange(id: any): void {
    if (id) {
      this.generateInvoicePreview(id);
    }
  }

  public generateInvoicePreview(id: number) {
    this.isLoading = true;
    getFile(
      {
        url: `/payment-documents/create-final-invoice-preview?studentEducationId=${id}`,
        method: "post",
      },
      false
    )
      .then((blob: string) => (this.source = blob))
      .catch((error) => {
        this.cancel();
      })
      .finally(() => {
        this.isLoading = false;
      });
  }

  protected ok(): void {
    this.$emit("ok");
  }

  protected cancel(): void {
    this.$emit("cancel");
  }
}
