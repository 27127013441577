














import { Component, Prop, Vue } from "vue-property-decorator";
import SaveButton from "@/components/Button/SaveButton.vue";

@Component({
  components: { SaveButton },
})
export default class UnfinishedInvoiceModal extends Vue {
  public name = "UnfinishedInvoiceModal";

  @Prop({ required: true, type: String })
  protected modalId!: string;

  protected ok(): void {
    this.$emit("ok");
  }
}
